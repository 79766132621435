@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/* You can add global styles to this file, and also import other style files */
:root {
  --colorPrimary: #0147ba;
  --colorPrimaryLight: #0147ba80;
  --colorSecondary: #7D7D7D;
  --colorSecondaryLight: #7D7D7D80;
  --colorAccent: #f5a800;
  --colorAccentLight: #f5a80080;
  --colorAzul: #004B93;
  --colorSuccess: #28A745;
  --colorDanger: #dc3545;
  --alto-app: 100vh;
  --ancho-app: 100vw;
}

.rem-font-1 {
  font-size: 1rem !important;
}

.rem-font-2 {
  font-size: 2rem;
}

.rem-font-12 {
  font-size: 1.2rem !important;
}

.shadow-none {
  box-shadow: none;
}

ion-card-title, ion-card-header, ion-button {
  font-family: "poppins";
  font-weight: bold;
}

.body-transparent {
  --background:transparent !important;
}

.background-none {
  --background: none;
}

.background-primario {
  --background: var(--colorPrimary) !important;
}

html,
body {
  height: auto;
  min-height: 100vh;
  font-family: "poppins", sans-serif;
  font-size: var(--font-size-base);
  line-height: 1.2rem;
  font-weight: 400;
  letter-spacing: 0rem;
  color: #F6F6F8;
  background-color: transparent !important;
  --ion-font-family: "poppins";
  --ion-color-primary: var(--colorPrimary);
  --ion-item-color: var(--colorSecondary);
  --background: transparent !important;
}

ion-content {
  --background: #F6F6F8;
}

.text-white {
  color: #ffffff !important;
}

.text-black {
  color: #000000 !important;
}

.text-grey {
  color: #aaaaaa !important;
}

.text-green {
  color: #81c71c !important;
}

.text-red {
  color: #cd4040 !important;
}

#header-sub, #header-sub2, #header-sub2-int {
  background-color: #f8f8f8;
  z-index: 9999;
}

#header {
  color: var(--colorPrimary);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "poppins", sans-serif;
  font-weight: 500;
  color: var(--colorPrimary);
  margin-bottom: 0rem;
  letter-spacing: 0rem;
}

p {
  font-family: "poppins", sans-serif;
  font-size: 1rem !important;
  color: var(--colorSecondary);
  letter-spacing: 0rem;
}

h1 {
  font-size: 1.5rem !important;
}

h2 {
  color: var(--colorPrimary);
  font-size: 1.2rem !important;
  margin-bottom: 1rem;
  position: relative;
  font-weight: bold !important;
}

h3 {
  font-size: 1rem !important;
  color: var(--colorSecondary);
}

p {
  font-size: 1rem;
  color: var(--colorSecondary);
}

.uppercase {
  text-transform: uppercase;
}

.padding-06rem {
  padding: 0.6rem !important;
}

.padding-05rem {
  padding: 0.5rem;
}

ion-card {
  border-radius: 0.5rem;
  box-shadow: 0 0 20px 0 var(--colorSecondaryLight) !important;
  z-index: 2 !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

ion-item {
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}

ion-item.item-has-focus {
  font-size: 1.1rem !important;
}

.center {
  text-align: center;
}

.label {
  font-size: 0.8rem !important;
  margin-top: 0.5rem !important;
  letter-spacing: 0rem !important;
}

.label-list {
  color: var(--colorSecondary) !important;
  font-size: 1rem !important;
  white-space: pre-wrap !important;
  opacity: 1 !important;
}

.input-list {
  font-weight: bold;
  font-size: 1rem !important;
  color: var(--colorSecondary) !important;
}

.label-stacked {
  color: var(--colorAccent) !important;
  font-size: 1.1rem !important;
}

.select-list {
  font-size: 1rem;
}

.toggle-primario-list {
  --handle-background-checked: var(--colorPrimary);
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.capitalize {
  text-transform: capitalize;
}

.color-primario {
  color: var(--colorPrimary) !important;
}

.color-secundario {
  color: var(--colorSecondary) !important;
}

.color-acento {
  color: var(--colorAccent) !important;
}

.color-blanco {
  color: white !important;
}

.bold {
  font-weight: bold !important;
}

.text-underline {
  text-decoration: underline;
}

.font-09 {
  font-size: 0.9rem !important;
}

.font-07 {
  font-size: 0.7rem !important;
}

.font-05 {
  font-size: 0.5rem !important;
}

.font-13 {
  font-size: 1.3rem !important;
}

.font-2 {
  font-size: 2rem !important;
}

.inline-block {
  display: inline-block;
}

.d-grid {
  display: grid;
}

.max-height-100 {
  max-height: 100%;
}

.max-width-100 {
  max-width: 100%;
}

.btn-primario {
  --background: var(--colorPrimary);
  --color: white;
  --background-hover: var(--colorSecondary);
  --color-hover:white;
  --border-radius: 10rem;
  --background-activated: var(--colorSecondary);
  --background-focused: var(--colorSecondary);
  height: 2.5rem;
  margin-bottom: 1rem;
}

.btn-primario:disabled,
.btn-primario[disabled] {
  --background: var(--colorPrimaryLight);
  border-radius: 10rem;
  opacity: 1;
  color: white;
  --box-shadow: none !important;
  --border-color: white;
}

.btn-secundario {
  --background: white;
  --border-color: var(--colorPrimary);
  --border-width: 2px;
  --border-radius: 10rem;
  --border-style: solid;
  --color: var(--colorPrimary);
  --background-hover:var(--colorPrimary);
  --color-hover:white;
  --box-shadow:none;
  --background-activated: var(--colorPrimary);
  --background-focused: var(--colorPrimary);
  height: 2.5rem;
  margin-bottom: 1rem;
}

.btn-terce {
  --background: none;
  --border-color: white;
  --border-width: 2px;
  --border-radius: 10rem;
  --border-style: solid;
  --color: white;
  --background-hover:var(--colorPrimary);
  --color-hover:white;
  --box-shadow:none;
  --background-activated: var(--colorPrimary);
  --background-focused: var(--colorPrimary);
  height: 2.5rem;
  margin-bottom: 1rem;
}

.btn-a {
  color: var(--colorPrimary);
  font-size: 1rem;
  font-weight: bold;
}

.btn-a:hover {
  color: var(--colorPrimary);
}

.row-height-007 {
  height: calc(var(--alto-app) * 0.07);
}

.row-height-010 {
  height: calc(var(--alto-app) * 0.1);
}

.row-height-015 {
  height: calc(var(--alto-app) * 0.15);
}

.row-height-020 {
  height: calc(var(--alto-app) * 0.2);
}

.row-height-025 {
  height: calc(var(--alto-app) * 0.25);
}

.row-height-030 {
  height: calc(var(--alto-app) * 0.3);
}

.row-height-032 {
  height: calc(var(--alto-app) * 0.32);
}

.row-height-035 {
  height: calc(var(--alto-app) * 0.35);
}

.row-height-038 {
  height: calc(var(--alto-app) * 0.38);
}

.row-height-040 {
  height: calc(var(--alto-app) * 0.4);
}

.row-height-048 {
  height: calc(var(--alto-app) * 0.48);
}

.row-height-050 {
  height: calc(var(--alto-app) * 0.5);
}

.row-height-060 {
  height: calc(var(--alto-app) * 0.6) !important;
}

.row-encabezado-presentacion {
  height: 80vh;
  justify-content: center;
}

.row-boton-presentacion {
  height: 13vh;
  justify-content: center;
}

.btn-a {
  color: var(--colorPrimary);
  font-size: 0.9rem;
}

.btn-a:hover {
  color: var(--colorPrimary);
}

.width-100 {
  width: 100%;
}

.row-height-050 {
  height: calc(var(--alto-app) * 0.5);
}

.flex-column {
  flex-direction: column;
}

.justify-around {
  justify-content: space-around;
}

.error-color {
  color: #e74c3c;
}

.p-left-0 {
  padding-left: 0;
}

.ios #header-sub2-int {
  padding-top: 6rem;
}

@media (max-width: 600px) {
  .ios #header-sub2-int {
    padding-top: 7rem;
  }
}
#header-sub2-int {
  padding-top: 5rem;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #4ba4da;
  background-color: #f8f8f8;
  border-bottom: 3px solid #4ba4da;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link2.active {
  background-color: #f8f8f8;
  border-bottom: 3px solid #e5e5e5 !important;
}

/*--------------------------------------------------------------
  # Loading
--------------------------------------------------------------*/
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.new-background-color {
  --background: #4ba4da;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

ion-loading.custom-class {
  --spinner-color: #0147ba !important;
}

.custom-class .loading-wrapper {
  background: transparent !important;
  box-shadow: none !important;
  backdrop-filter: none !important;
}

.cusstom-class ion-backdrop {
  opacity: 0.2;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #d6d8da;
  font-size: 18px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.ion-page {
  height: 100%;
}

/*--------------------------------------------------------------
  # Menú
--------------------------------------------------------------*/
ion-fab.btnMenu ion-menu-toggle ion-fab-button {
  --color: black;
  --background: transparent;
  --box-shadow: none;
}
ion-fab.btnMenu ion-menu-toggle ion-fab-button ion-icon {
  font-size: 30px;
}

ion-card.logo {
  margin-left: 0 !important;
  text-align: center;
  box-shadow: none;
  background: transparent !important;
  position: static;
  width: 100%;
}
ion-card.logo ion-icon.logo {
  width: 100px;
  height: 50px;
}

/*--------------------------------------------------------------
  # Index
--------------------------------------------------------------*/
.grid {
  height: 97%;
}

.row-sup {
  height: 70%;
}

.row-inf {
  height: 41.5%;
}
.row-inf ion-col {
  height: 100%;
}

.my-modalMov-class .modal-wrapper {
  height: 60%;
  top: 40%;
  position: absolute;
  display: block;
  border-radius: 0.5rem 0.5rem 0 0;
}

.modalCuenta .modal-wrapper {
  height: 50%;
  top: 50%;
  position: absolute;
  display: block;
  border-radius: 0.5rem 0.5rem 0 0;
}

.my-modalContacto .modal-wrapper {
  height: 40%;
  top: 60%;
  position: absolute;
  display: block;
  border-radius: 0.5rem 0.5rem 0 0;
}

.my-modalContactoDos .modal-wrapper {
  height: 45%;
  top: 55%;
  position: absolute;
  display: block;
  border-radius: 0.5rem 0.5rem 0 0;
}

.my-modalListaContactos .modal-wrapper {
  top: 3.6rem;
  height: 100%;
  position: absolute;
  display: block;
  border-radius: 0.5rem 0.5rem 0 0;
}
.my-modalListaContactos .ion-page {
  padding-bottom: 4rem;
}
.my-modalListaContactos .lista-contactos {
  overflow-y: scroll;
  max-height: 70vh;
}

.modalQR .modal-wrapper {
  height: 100%;
  top: 0;
  position: absolute;
  display: block;
}

@media screen and (max-height: 750px) {
  .my-modalMov-class .modal-wrapper {
    height: 60%;
    top: 40%;
  }

  .modalCuenta .modal-wrapper {
    height: 60%;
    top: 40%;
  }
}
.icono-cerrar-modal {
  font-size: 2rem;
  color: var(--colorSecondary);
}

.icono-cerrar-modal-blue {
  font-size: 2rem;
  color: white;
}

.linea-bottom {
  border-bottom: solid 0.06rem var(--colorSecondary);
  margin-left: 1rem;
  margin-right: 1rem;
}

.backdropActivo {
  z-index: 10;
  --ion-backdrop-color: white;
  opacity: 0.7;
}

.backdropOculto {
  display: none;
}

.alert-css .alert-wrapper {
  border-radius: 0.5rem !important;
}
.alert-css .alert-title {
  color: var(--colorPrimary);
}
.alert-css .alert-message {
  color: var(--colorSecondary) !important;
}
.alert-css .cancel {
  color: var(--colorSecondary) !important;
}
.alert-css .alert-button-group button {
  color: var(--colorPrimary);
}

.alert-img-css .alert-wrapper, .alert-img-css .alert-message {
  padding: 0 !important;
}
.alert-img-css .alert-message {
  max-height: none !important;
}
.alert-img-css .alert-message img {
  width: 100%;
}
.alert-img-css .alert-head {
  display: none !important;
}
.alert-img-css .alert-button-group {
  display: flex !important;
  justify-content: flex-end !important;
  position: absolute;
}
.alert-img-css .alert-button-group .alert-button {
  align-items: center;
  border-radius: 50%;
  margin: 0;
}
.alert-img-css .alert-button-group .alert-button span {
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
  color: black;
  opacity: 0.33;
  font-size: 1.3rem;
}

.css-toast {
  --border-radius: 0.5rem;
  --max-width:70%;
  --background: #353535e0;
  top: -5rem;
  text-align: center;
}

/*--------------------------------------------------------------
  # Movimientos
--------------------------------------------------------------*/
.no-border {
  --border-width: 0;
  --inner-border-width: 0;
}

.margin-item {
  margin-top: 0.5rem;
}

.padding-0 {
  padding: 0 !important;
}

.movimiento-i {
  padding-top: 0.4rem;
  font-size: 2rem;
}

.color-negativo {
  color: var(--colorAccent);
}

.color-positivo {
  color: var(--colorPrimary);
}

.movimiento-detalle {
  font-size: 0.8rem !important;
}

.total {
  font-size: 0.9rem !important;
  font-weight: bold;
  margin-right: -1rem;
}

.movimiento-positivo {
  color: var(--colorPrimary);
}

.sin-movimientos {
  text-align: center;
  font-size: 1.2rem;
}

.col-info {
  margin-left: -1rem;
}

.imagen-sin-movimientos {
  margin-top: 8%;
}

.logo-movimientos-completos {
  width: 100px;
  height: 50px;
}

.chip-movimientos {
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: var(--colorPrimary);
  color: white;
}
.chip-movimientos ion-icon {
  color: white;
}
.chip-movimientos ion-label {
  margin-bottom: 0.1rem;
}

/*--------------------------------------------------------------
  # Estadisticas
--------------------------------------------------------------*/
.titulo-gastos {
  font-size: 1.125rem;
  color: var(--colorSecondary);
  font-weight: 800;
  margin-bottom: 1.3rem;
  margin-top: -0.5rem;
}

a {
  color: var(--colorSecondary) !important;
  text-decoration: none !important;
}

.detalle-accordion {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--colorSecondary);
}
.detalle-accordion .dato {
  margin-bottom: 0.2rem;
}

.titulo-17 {
  font-size: 1.7rem;
  margin-top: 5%;
}

.card-estadistica {
  height: 50% !important;
  margin-bottom: 2%;
  font-weight: bold;
  margin-top: 4rem;
}

.contenedor-grafico {
  height: 90%;
}

.saldo {
  color: var(--colorAccent);
  font-size: 1.6rem !important;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.titulo-movimientos-estadisticas {
  margin-top: 1rem;
  color: var(--colorAccent);
  text-align: left;
  margin-left: 1rem;
}

.grafico {
  height: 80%;
}
.grafico .contenedor-grafico {
  height: 80%;
  border-bottom: solid 1px gray;
}
.grafico .contenedor-grafico .columnas {
  display: inline-block;
  background: var(--colorSecondaryLight);
  width: 6%;
  margin: 0 6.6%;
}
.grafico .contenedor-grafico .columna-activa {
  background: var(--colorAccent);
}
.grafico .meses .mes {
  display: inline-block;
  margin: 2% 2% 0 2%;
  width: 15%;
  color: var(--colorAccent);
  font-weight: 500;
  font-size: 0.75rem;
  transform: rotate(-90deg);
  margin-top: 0.5rem;
  transform: rotate(270deg);
}

ion-card.card-movimientos {
  text-align: center;
  height: 42%;
  margin-bottom: 2rem;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}
ion-card.card-movimientos ion-col .col-info {
  font-size: 1rem;
}

/*--------------------------------------------------------------
  # Servicios
--------------------------------------------------------------*/
.iconos-principales-servicios {
  font-size: 3.5rem;
  color: var(--colorSecondary);
  margin-top: 0.5rem;
}

.contenedor-principal {
  display: grid;
  place-content: center;
  justify-items: center;
}

.mr-menos-15rem {
  margin-right: -1.5rem;
}

.ml-menos-15rem {
  margin-left: -1.5rem;
}

.contenedor-icono-servicio {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: white;
  --box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.2);
}

.texto-principal-servicio {
  color: var(--colorSecondary);
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.contenedor-destacados-scroll {
  overflow-y: auto;
  height: 15.625rem;
}

.card-destacados-servicios {
  min-height: 40%;
  max-height: 40%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.card-recientes-servicios {
  margin-top: 1em;
  margin-left: 1rem;
  margin-right: 1rem;
}

.card-destacados-pagos {
  padding: 0;
}

.contenedor-titulo-secundario {
  padding: 0.625rem 0.6rem 0;
}

.titulos-secundarios {
  font-size: 1rem;
  color: var(--colorPrimary);
}

.line-hr {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  border-top: 1px solid var(--colorSecondary);
}

.line-light-hr {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  border-top: 1px solid var(--colorSecondaryLight);
}

.line-none {
  border-top: 1px solid none;
}

.contenedor-destacado {
  display: flex;
  align-items: center;
}

.iconos-destacados {
  max-height: 3rem;
  width: 3rem;
  color: var(--colorPrimaryLight);
  margin-left: 1rem;
  border-radius: 0.5rem;
}

.texto-destacados {
  font-size: 0.8rem !important;
  font-weight: normal !important;
  line-height: 1.2rem;
  color: var(--colorSecondary);
}

.iconos-reciente {
  max-width: 5rem;
  color: var(--colorPrimary);
  border-radius: 10%;
}

.row-recientes {
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
  min-height: 7rem;
}

/*--------------------------------------------------------------
  # Registro
--------------------------------------------------------------*/
.content-registro {
  font-family: "poppins";
}

.image-icono-a {
  max-width: 2.5rem;
  margin-top: 1rem;
}

.boton-back-login {
  color: black;
  font-size: 2.5rem;
  margin-top: 1rem;
}

.grid-registro {
  height: 100%;
}
.grid-registro ion-list {
  margin-top: 2rem;
}
.grid-registro ion-icon {
  color: var(--colorAccent) !important;
}

.image-slide-1 {
  max-height: 14vh;
  margin-top: 4vh;
  display: block;
}

.titulo-slide {
  text-align: left;
  font-weight: bold;
  margin: 2rem 0 1rem;
  line-height: 1.2rem;
  font-size: 1.125rem;
  color: var(--colorPrimary);
}

.texto-slide {
  text-align: left;
  font-size: 1rem;
  color: var(--color-base-gris);
}

.texto-slide > p {
  margin: 0px;
}

.img-carita {
  max-width: 2.6rem;
  margin: -0.5rem 0rem 0rem 0.5rem;
}

.image-error {
  max-width: 100%;
}

.error {
  position: absolute;
  left: 35%;
  top: 26%;
  color: white;
}

.row-verificacion-celular {
  height: calc(var(--alto-app) * 0.4);
}

/*--------------------------------------------------------------
  # Transferir-dinero
--------------------------------------------------------------*/
.monto-transferir {
  color: var(--colorPrimary);
  font-size: 3rem;
  margin: 2rem 2rem;
}

.input-monto {
  text-align: center;
}

.input-monto-transferir {
  border-bottom: 1px solid var(--colorSecondaryLight);
  margin-left: -1.5rem !important;
}

.error {
  color: red;
  position: inherit;
  display: inline-block;
  width: 100%;
  margin: 5px 0;
}
.error span {
  display: inline-block;
}

.div-obtener-cvu {
  position: relative;
  top: 50%;
}

/*--------------------------------------------------------------
  # Agenda
--------------------------------------------------------------*/
.icono-agregar-contacto {
  font-size: 1.2rem;
  color: white;
  background: var(--colorPrimary);
  padding: 1rem;
  border-radius: 50%;
  margin: 1rem;
}

.texto-agregar-contacto {
  margin-left: -0.5rem;
  margin-bottom: 0.5rem;
}

.card-agenda {
  height: 60%;
}
.card-agenda ion-card-content {
  height: 100%;
  overflow-y: auto;
}
.card-agenda ion-card-content .nav-tabs .nav-item {
  width: 50%;
  font-size: 1rem;
}
.card-agenda ion-card-content .nav-tabs .nav-link {
  color: white !important;
  background: var(--colorSecondaryLight);
}
.card-agenda ion-card-content .nav-tabs .nav-link ion-icon {
  margin-right: 5px;
  margin-bottom: -2px;
}
.card-agenda ion-card-content .nav-tabs .nav-item.show .nav-link, .card-agenda ion-card-content .nav-tabs .nav-link.active {
  color: white !important;
  background-color: var(--colorPrimary);
}
.card-agenda ion-card-content .nav-tabs .nav-item.show .nav-link ion-icon, .card-agenda ion-card-content .nav-tabs .nav-link.active ion-icon {
  margin-right: 5px;
  margin-bottom: -2px;
}
.card-agenda ion-card-content .referencia {
  font-size: 0.9rem;
  font-weight: bold;
}
.card-agenda ion-card-content .caption {
  font-size: 12px;
}
.card-agenda ion-card-content .tab-content {
  overflow-y: auto;
}

.icono-agenda-lista {
  margin-inline-end: 1rem !important;
}

.favoritos-agenda {
  border-radius: 0.5rem 0 0.5rem 0 !important;
}

.recientes-agenda {
  border-radius: 0.5rem 0 0.5rem 0 !important;
}

.card-encabezado {
  height: 30%;
  text-align: center;
}
.card-encabezado ion-card-content {
  height: 100%;
  overflow-y: auto;
}

/*--------------------------------------------------------------
  # Nuevo CVU
--------------------------------------------------------------*/
.item-buscar-contacto {
  margin: 0 2rem;
  font-size: 1rem;
}

.contacto-verificado {
  margin: 3rem 0rem;
}

.titulos-contacto-verificado {
  font-size: 1rem;
  color: var(--colorPrimary);
}

.datos-contacto-verificado {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.icono-check-transferencia {
  font-size: 6rem;
  border-radius: 50%;
  padding: 2rem;
  color: var(--colorPrimary);
  background: white;
  margin-top: 35%;
}

/*--------------------------------------------------------------
  # Realizar Transferencia
--------------------------------------------------------------*/
.monto-a-transferir {
  font-size: 3rem;
  color: var(--colorPrimary);
  font-weight: bold;
  margin: 1rem;
}

.razon-social-a-transferir {
  font-size: 1rem;
  color: var(--colorPrimary);
  text-transform: capitalize;
  font-weight: bold;
}

/*--------------------------------------------------------------
  # Modal Detalle
--------------------------------------------------------------*/
.icono-detalle-favorito {
  font-size: 4.5rem;
  color: var(--colorAccent);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.boton-agregar-fav {
  --color: var(--colorPrimary);
  --background: none;
  --border-radius: 0.5rem;
  --box-shadow: none;
}
.boton-agregar-fav ion-icon {
  color: var(--colorPrimary);
  margin-right: 0.5rem;
}

.boton-agregar {
  --background: var(--colorSecondary);
  --color: white;
  --color-hover: white;
  --border-radius: 0.5rem;
  --box-shadow: none;
  height: 2.8rem;
  padding: 0px 2.2rem;
  font-size: 1rem;
  text-transform: capitalize;
  margin-top: 2rem;
}

.lista-detalle-fav {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.card-transferir ion-label {
  color: var(--colorAccent) !important;
}
.card-transferir .agregar-favoritos {
  color: var(--colorSecondary) !important;
}

/*--------------------------------------------------------------
  # Comprobante
--------------------------------------------------------------*/
.fondo-primario {
  --background: var(--colorPrimary);
}

.card-comprobante {
  border-radius: 0.5rem;
  background-color: white;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
  text-align: center;
}
.card-comprobante .linea {
  color: var(--colorSecondary);
}
.card-comprobante .contenedor-ticket {
  line-height: 1rem;
  text-align: left;
}
.card-comprobante .contenedor-ticket .contenedor-imagen {
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-comprobante .contenedor-ticket .contenedor-imagen .img-rapipago {
  margin-bottom: 3%;
  max-width: 15rem;
  display: inline-block;
}
.card-comprobante .contenedor-ticket .contenedor-imagen ion-icon.logo {
  margin-bottom: 5%;
  width: 100px;
  height: 30px;
}

.botones-comprobante {
  margin-left: 1rem;
  margin-right: 1rem;
}

/*--------------------------------------------------------------
  # Comprobante de Transferencia
--------------------------------------------------------------*/
.contenedor-imagen-transferencia {
  height: 53%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagen-ok {
  width: 10rem;
  margin: auto;
}

/*--------------------------------------------------------------
  # Cosas Genericas
--------------------------------------------------------------*/
ion-toolbar {
  --background: var(--colorPrimary);
}
ion-toolbar ion-title {
  text-align: center;
  color: white;
}

ion-toolbar.toolbarPrimary {
  --background: var(--colorPrimary);
}
ion-toolbar.toolbarPrimary ion-title {
  text-align: center;
  color: white;
}

.titulo-toolbar {
  color: var(--colorSecondary);
  margin: 0;
}

.titulo-toolbar-black {
  color: white;
  margin: 0;
}

.titulo-toolbar-secondary {
  color: white !important;
  margin: 0;
}

.degrade {
  --background: white;
}

.bg-telepagos {
  position: absolute;
  width: 100%;
  height: 9.125rem;
  background: var(--colorPrimary);
  border-radius: 0rem 0rem 0.3125rem 0.3125rem;
}

.bg-white {
  --background: white !important;
}

.bg-telepagos-primary {
  position: absolute;
  height: 20%;
  width: 100%;
  background: var(--colorPrimary);
}

.bg-telepagos-primary::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -3rem;
  height: 3rem;
  width: 100%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  box-shadow: 0 -1.5rem 0 0 var(--colorPrimary);
}

.bg-telepagos-secondary {
  position: absolute;
  height: 25%;
  width: 100%;
  background: var(--colorPrimary);
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.bg-grey {
  --background: #F6F6F8;
}

.bg-telepagos-grey {
  background: #F6F6F8;
  position: absolute;
  width: 100%;
  height: 50%;
  margin-top: 9.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.card-telepagos-white {
  background: white;
  position: absolute;
  width: 100%;
  height: 82%;
  border-radius: 0.5rem 0.5rem 0 0;
}

.z-index-2 {
  z-index: 2;
}

.margin-t5 {
  margin-top: 5rem;
}

.logo-telepagos {
  width: 8rem;
  margin-top: 1rem;
}

.pocopaddin {
  padding: 1rem;
}

.m-lr {
  margin-left: 1rem;
  margin-right: 1rem;
}

.card-telepagos {
  z-index: 3 !important;
  position: absolute;
  margin-top: -2.5rem;
  width: -webkit-fill-available;
}

.card-telepagos-nm {
  margin-left: 0;
  margin-right: 0;
  margin-top: 1rem;
  box-shadow: none !important;
  z-index: 3 !important;
}

.titulo-telepagos-left {
  text-align: left;
  font-weight: bold;
  margin-top: 1rem;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: var(--colorPrimary);
}

.card-telepagos-nm-l {
  margin-left: 0;
  margin-right: 0;
  margin-top: 2.5rem;
  box-shadow: none;
  z-index: 2 !important;
}

.titulo-telepagos-primario {
  color: white;
  z-index: 1;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

.titulo-telepagos-secundario {
  color: white !important;
  z-index: 1;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  font-weight: bold;
}

.label-input {
  font-size: 0.8rem;
  color: var(--colorSecondary);
  margin-left: 0.5rem;
}

.input {
  border: solid 0.1rem var(--colorSecondary);
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}

.input:focus :active :hover {
  border: solid 0.1rem var(--colorPrimary);
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}

.boton-atras {
  --color: white;
  width: 1.2rem;
}

.boton-atras-black {
  --color: white;
  width: 1.2rem;
}

.btn-filtro {
  width: 1.5rem;
}

ion-toolbar.toolbarSecondary {
  --background: white;
}
ion-toolbar.toolbarSecondary ion-title {
  text-align: center;
}

ion-content.degrade {
  --background: linear-gradient(to bottom, var(--colorPrimary) 10%, #ffffff 25%);
}

ion-card.center {
  --background: white;
  text-align: center;
}

ion-title {
  color: var(--colorPrimary);
  font-size: 1.1rem !important;
  margin-bottom: 1rem;
}

.titulo-primario {
  color: var(--colorPrimary);
  font-size: 1.2rem;
}

.titulo-secundario {
  color: var(--colorSecondary);
  font-size: 1.2rem;
}

.divError {
  background: var(--colorPrimary);
  height: 100px;
  position: relative;
  width: 95%;
  border-radius: 0.5rem;
}
.divError .izquierda {
  float: left;
  height: 100%;
  display: table;
}
.divError .izquierda p {
  display: table-cell;
  vertical-align: middle;
  color: white;
  font-weight: bold;
  font-size: 2.5rem;
  padding-left: 10px;
}
.divError .derecha {
  height: 100%;
  display: grid;
  display: table;
}
.divError .derecha p {
  display: table-cell;
  vertical-align: middle;
  color: white;
  padding-left: 10px;
  line-height: 1.2rem;
}

.divError:after {
  content: "";
  position: absolute;
  left: 99%;
  width: 0;
  height: 0;
  top: 0;
  border-top: 0px solid transparent;
  border-left: 25px solid var(--colorPrimary);
  border-bottom: 31px solid transparent;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.tituloObtenerCvu {
  color: var(--colorSecondary);
  font-size: 1rem;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

ion-checkbox {
  --border-color: var(--colorAccent);
  margin: 0px 1rem 0px 0px;
  --background-checked: var(--colorAccent);
  --border-color-checked: var(--colorAccent);
}

/****Creo que esto lo vamos a usar varias veces*****/
.tarjeta {
  background: linear-gradient(to right, #f4f4f4 50%, #e3e7ea 100%);
  position: absolute;
  height: 10rem;
  width: 80%;
  border-radius: 0.5rem;
  box-shadow: #9b9ea0 1px 0px 5px;
}

.encabezado-tarjeta {
  text-align: left;
}
.encabezado-tarjeta div {
  display: inline-block;
}
.encabezado-tarjeta .icono-tarjeta {
  width: 3rem;
  margin: 1rem 0.7rem;
}
.encabezado-tarjeta .numero-tarjeta {
  padding-left: 0.5rem;
  color: var(--colorPrimary);
}

.cuerpo-tarjeta .icono-numero-tarjeta {
  width: 2rem;
  margin-right: 1rem;
}
.cuerpo-tarjeta .numero-tarjeta {
  padding-left: 0.5rem;
  color: var(--colorPrimary);
  font-weight: bold;
}
.cuerpo-tarjeta .vencimiento-tarjeta {
  color: var(--colorPrimary);
  float: right;
  padding-right: 3rem;
}
.cuerpo-tarjeta .razon-social-tarjeta {
  color: var(--colorPrimary);
  padding-right: 1rem;
}
.cuerpo-tarjeta div {
  display: inline-block;
  margin-top: 0.1rem;
}

.contenedor-boton {
  text-align: center !important;
}

/*--------------------------------------------------------------
  # Recarga Celular
--------------------------------------------------------------*/
ion-card-content.card-content {
  overflow-y: auto;
}

ion-grid.grid-recargas {
  overflow-y: auto;
}

.card-chicos {
  --background: rgba(255, 255, 255, 0.5) !important;
  height: calc(var(--ancho-app) * 0.40);
  width: calc(var(--ancho-app) * 0.38);
  margin: 0;
}

/***Reemplazar esto con el estilo de label***/
.texto-recargas {
  margin-top: 4px;
  color: var(--colorSecondary);
  font-size: 12px;
  text-transform: capitalize;
  overflow: hidden;
  text-align: center;
}

.m-t-2 {
  margin-top: 2rem;
}

.m-t-5 {
  margin-top: 5rem;
}

.m-t-10 {
  margin-top: 10rem;
}

/*--------------------------------------------------------------
  # Pagar Servicios
--------------------------------------------------------------*/
.nombre-empresa-servicio {
  font-size: 0.8rem;
}

ion-card-content {
  height: 100%;
}

.card-arriba {
  height: 22%;
  margin-bottom: calc(var(--alto-app) * 0.01);
}

.card-slide {
  height: 33%;
}

.card-content ion-card-title {
  --color: var(--colorPrimary);
  font-size: 100%;
  height: 15%;
  text-align: center;
}

.contenedor-image {
  height: 70%;
  align-items: center;
}

.img-top {
  height: 100%;
}

ion-slides.destacados {
  height: 90%;
  --bullet-background: #b4b4b4;
  --bullet-background-active: var(--colorPrimary);
}

.card-content-abajo {
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;
}

.contenedor-image-slide {
  height: 35%;
}

.img-slide {
  height: 100%;
}

.img-slide-recargas {
  max-width: 7rem;
  border-radius: 10%;
}

.item-interactive.ion-valid {
  --highlight-background: var(--colorAccent);
}

/*--------------------------------------------------------------
  # Busqueda
--------------------------------------------------------------*/
.botonCerrar {
  --color:var(--colorSecondary);
}

.grid-busqueda .resultados-busqueda {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  --border-style: none;
}
.grid-busqueda img {
  border-radius: 10%;
  margin-left: 1rem;
  max-height: 2.5rem !important;
}
.grid-busqueda ion-label {
  font-size: 1rem !important;
}
.grid-busqueda ion-list {
  padding: 0 !important;
}

.barra-busqueda ion-item {
  --border-radius: 0.5rem;
  --border: 1px solid var(--colorSecondary);
  --border-width: 0.2rem;
  --color-activated: transparent;
  --color-focused: transparent;
  --highlight-color-valid: transparent;
  --highlight-color-focused: transparent;
  --highlight-color-invalid: transparent;
}

.imagen-logo {
  max-height: 2rem;
}

.label-lista {
  min-width: 80%;
  max-width: 80%;
  text-transform: capitalize;
  color: gray;
}

.label-lista-adentro {
  min-width: 80%;
  max-width: 100%;
  text-transform: capitalize;
  color: black;
  font-size: 0.9rem !important;
  line-height: 1.2rem;
  letter-spacing: 0px;
  margin-left: 1rem;
}

.texto-no-encontraron {
  color: gray;
}

.oculto-display {
  display: none;
}

.titulo-empresa {
  padding-left: 1rem;
}

.spinner {
  margin: auto;
}

/*--------------------------------------------------------------
  # Instrucciones de Escanear
--------------------------------------------------------------*/
.card-arriba-escanear {
  height: 96%;
}

.item-instrucciones-escanear {
  color: #414042;
  --border-radius: 0.5rem;
  font-size: 0.8rem;
  margin: 1rem 0;
}

.fondo-azul {
  --background: var(--colorPrimaryLight);
}

.img-escanear {
  min-width: 2.5rem;
}

/*--------------------------------------------------------------
  # Detalle destacado
--------------------------------------------------------------*/
.contenedor-imagen-detalle {
  z-index: 2 !important;
  position: relative;
}
.contenedor-imagen-detalle img {
  width: 7rem;
  background: white;
  padding: 0.5rem;
  border-radius: 50%;
}

/*--------------------------------------------------------------
  # Mi cuenta
--------------------------------------------------------------*/
.card-menu-micuenta {
  border-radius: 0rem;
}

.toolbar-primario {
  z-index: 1;
  position: absolute;
  height: 13.125rem;
  --background: var(--colorPrimary);
  border-radius: 0rem 0rem 0.3125rem 0.3125rem;
}

.card-menu-micuenta {
  margin: auto;
  height: 100%;
}

.background-micuenta {
  background: var(--colorPrimary);
  width: 100%;
}

.card-principal-telepagos {
  z-index: 2;
  margin-top: 5.5rem;
  margin-bottom: 0.563rem;
  height: 7.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
}

.email-telepagos {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 0.875rem;
  color: var(--colorSecondary);
}

.nombre-telepagos {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 0rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--colorSecondary);
}

.contenedor-circulo-telepagos {
  width: 100%;
  display: flex;
  justify-content: center;
}

.letras-principales-telepagos {
  margin-top: 2.5rem;
  z-index: 3;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 5.063rem;
  height: 5.063rem;
  border-radius: 50%;
  border: 4px solid var(--colorPrimary);
  box-sizing: border-box;
  color: var(--colorAccent);
  font-weight: bold;
  font-size: 2.5rem;
}

.list-items-secundarios-telepagos {
  padding: 2%;
  background-color: #F6F6F8 !important;
}
.list-items-secundarios-telepagos ion-item {
  display: flex;
  align-items: center;
  --border-style: none;
  margin-bottom: 0.625rem;
  min-height: 3.938rem;
  box-shadow: 0rem 0.25rem 0.5rem var(--colorSecondaryLight) !important;
  border-radius: 0.5rem;
  color: var(--colorSecondary);
  background-color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.icono-menu {
  color: var(--colorAccent);
  margin-right: 1.125rem;
}

.iconoFlecha {
  color: #7d7d7d;
}

.texto-telepagos {
  font-size: 1.063rem !important;
}

ion-item.cerrar-sesion-telepagos {
  margin-top: 1.688rem;
  text-align: center;
  display: flex;
  align-items: center;
  height: 2.25rem !important;
  --background: var(--colorPrimary);
  background: var(--colorPrimary);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 1.125rem;
  color: #ffffff;
}

.items-mi-cuenta {
  font-weight: bold;
  font-size: 0.9rem !important;
}

/*--------------------------------------------------------------
  # Mi cuenta / Billetera
--------------------------------------------------------------*/
.titulocvu {
  color: var(--colorSecondaryLight);
  font-size: 0.8rem;
}

.cvu-contenido {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.sector-qr {
  margin-top: 10%;
}

.contenedor-qr {
  padding: 1rem 3rem;
}

.contenedor-spinner {
  display: flex;
}

/*--------------------------------------------------------------
  # Mi cuenta / Cerrar Sesion
--------------------------------------------------------------*/
.icono-cerrar-sesion {
  width: 100px;
  height: 100px;
  color: var(--colorPrimary);
}

.subtitulo {
  margin-top: 5%;
  font-size: 0.9rem;
  color: var(--colorSecondaryLight);
  padding: 1rem;
}

.card-cerrar-sesion {
  margin-top: 20%;
  height: 70%;
}
.card-cerrar-sesion ion-card-content {
  margin-top: 35%;
}

/*--------------------------------------------------------------
  # Mi cuenta / Borrar Cuenta
--------------------------------------------------------------*/
.btn-borrar {
  margin-top: 10%;
  text-transform: capitalize;
  --padding-start: 5rem;
  --padding-end: 5rem;
  font-size: 1rem;
}

/*--------------------------------------------------------------
  # Mi cuenta / Borrar Cuenta / Verificar Email
--------------------------------------------------------------*/
/*--------------------------------------------------------------
  # Mi cuenta / Datos Personales / Celular
--------------------------------------------------------------*/
.row-celular ion-col {
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}
.row-celular ion-col ion-item {
  font-size: 1rem;
}
.row-celular ion-col ion-item ion-label {
  color: gray !important;
}

/*--------------------------------------------------------------
  # Mi cuenta / Datos Personales / Celular / Verificar Codigo
--------------------------------------------------------------*/
.item-verificar-codigo {
  --padding-start: 0;
  --padding-end: 0;
  font-size: 1rem;
}
.item-verificar-codigo .prefijo {
  text-align: right;
}

#timer {
  font-size: 20px;
  color: var(--colorPrimary);
  letter-spacing: 0rem;
  text-align: center;
}

#timer span {
  font-size: 60px;
  color: var(--colorPrimary);
  margin: 0 3px 0 15px;
}

#timer span:first-child {
  margin-left: 0;
}

/*--------------------------------------------------------------
  # Mi cuenta / Preferencias / Dispositivos
--------------------------------------------------------------*/
.elemento {
  margin: 1rem 0;
}

.img-icono-dispositivos {
  font-size: 1.15rem;
}

/*--------------------------------------------------------------
  # Mi cuenta / Preguntas Frecuentes
--------------------------------------------------------------*/
.btn-preguntas-frecuentes {
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.2rem;
  letter-spacing: 0px;
  border: none;
  margin: 0px;
  font-weight: bold;
  display: inline-block;
  padding: 20px 10px;
  border-radius: 0px;
  background-color: white;
  border: 0px;
  color: var(--colorPrimary);
}

.contenedor-pregunta {
  display: flex;
  align-items: center;
}

.flecha-preguntas {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  color: var(--colorPrimary);
}

.card-preguntas-frecuentes {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #e5e5e5;
  background-clip: border-box;
  border: none;
  border-radius: 0rem;
}

.card-header-preguntas-frecuentes {
  padding: 0rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid #dee2e6;
  border-bottom: none;
}

.card-header-preguntas-frecuentes .btn-preguntas-frecuentes:hover,
.card-header-preguntas-frecuentes .btn-preguntas-frecuentes.focus,
.card-header-preguntas-frecuentes .btn-preguntas-frecuentes:focus,
.card-header-preguntas-frecuentes .btn-preguntas-frecuentes.active {
  color: var(--colorSecondaryLight) !important;
  border: 0px;
  background-color: white !important;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(1, 23, 44, 0);
  -moz-box-shadow: 0px 0px 7px 0px rgba(1, 23, 44, 0);
  box-shadow: 0px 0px 7px 0px rgba(1, 23, 44, 0);
}

.card-body-contenido-preguntas {
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.2rem;
  letter-spacing: 0px;
  font-weight: 400;
  color: gray;
  background: white;
}

/*--------------------------------------------------------------
  # Recibir dinero cvu
--------------------------------------------------------------*/
.card-recibir-dinero-cvu {
  margin-top: 5rem;
  height: 10rem;
}
.card-recibir-dinero-cvu ion-card-title {
  color: var(--colorAccent);
}

.card-content-cvu {
  height: 25%;
}

.icono-copiar-cvu {
  color: var(--colorPrimary);
  float: right;
}

.divAlert-28px {
  background: var(--colorPrimary);
  height: 33px;
  float: right;
  width: 30%;
  border-radius: 0.5rem;
  margin-right: 1rem;
  animation: efectodesaparecer 3s forwards;
}
.divAlert-28px .derecha {
  height: 100%;
  display: grid;
  display: table;
}
.divAlert-28px .derecha p {
  display: table-cell;
  vertical-align: middle;
  color: white;
  padding-left: 10px;
  line-height: 1.2rem;
}

@keyframes efectodesaparecer {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*--------------------------------------------------------------
  # Recibir dinero qr
--------------------------------------------------------------*/
.contenedor-qr-recibir-dinero {
  display: grid;
  height: 65%;
  align-items: center;
}

.subtitulo-sup {
  font-size: 0.9rem;
  color: gray;
}

.height-80 {
  height: 80%;
}

/*--------------------------------------------------------------
  # Validacion identidad / Persona expuesta
--------------------------------------------------------------*/
.row-encabezado-persona-expuesta {
  height: 75vh;
  justify-content: center;
}

.row-boton {
  height: 13vh;
  justify-content: center;
}

.grid-persona-exp {
  margin: 15px 0;
  grid-template-columns: repeat(2, 1fr);
}

.grid-box {
  margin: 15px 0;
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-box > div {
  display: flex;
  margin: 0 15px;
  padding: 5px 10px;
}

.grid-box > div > label {
  margin: 0 5px;
}

@media (max-width: 800px) and (orientation: landscape) {
  .row-encabezado-persona-expuesta {
    margin-top: 0px;
  }
}
/*--------------------------------------------------------------
  # Validacion identidad / Selfie
--------------------------------------------------------------*/
.imagen-selfie > img {
  min-width: 2rem;
  max-width: 2rem;
}

.imagen-selfie {
  padding: 5%;
}

.texto-instrucciones-selfie {
  color: white;
  text-align: center;
  width: 100%;
  height: 15%;
  font-size: 1.5rem;
  padding: 0.5rem;
  position: absolute;
  top: 1rem;
}

.corroborar-foto-selfie {
  position: absolute;
  color: black;
  text-align: center;
  font-size: 1.5rem;
  right: 1px;
  background: white;
}

/*--------------------------------------------------------------
  # Login password
--------------------------------------------------------------*/
.password_contenedor {
  width: 100%;
  font-size: 2rem;
  --padding-bottom: 0px;
  --color: var(--colorSecondary);
  letter-spacing: 0rem;
  --padding-top: 0.8rem;
  --padding-start: 0;
  --color-focused: var(--colorPrimary);
  --color-hover: var(--colorPrimary);
  --color-activated: var(--colorPrimary);
}

.ios .password_contenedor {
  font-size: 1.5rem;
  margin-top: 0.4rem;
}

.bt-borrar {
  --border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  --padding-end: 3px;
  --padding-start: 2px;
  --padding-top: 2px;
  --padding-bottom: 2px;
  --background: var(--colorSecondary);
  margin-left: 1rem;
}

.botonera {
  margin-bottom: 3rem;
}
.botonera ion-row ion-col ion-button {
  --box-shadow: 0rem 0.1rem 0.1rem 0rem rgba(0, 0, 0, 0.2);
  width: 4.5rem;
  height: 4.5rem;
  --background: white;
  --border-radius: 50%;
  --color: var(--colorPrimary);
  font-size: 1.8rem;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
  --color-activated: var(--colorPrimary);
}

.logo-login {
  width: 174px;
  height: 50px;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.card-telepagos-shadow {
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25) !important;
  border-radius: 0.5rem;
}

.height-8rem {
  height: 8.3rem;
}

.m-t-07rem {
  margin-top: -0.7rem;
}

.font-size-1-5-rem {
  font-size: 1.5rem;
}

.position-icon {
  right: 0;
  position: absolute;
  margin-right: 0.5rem;
}

.precio {
  font-weight: 700 !important;
  font-size: 2.25rem !important;
}

.scroll {
  --overflow: hidden;
}

@media only screen and (max-height: 650px) {
  .scroll {
    --overflow: auto;
  }
}
.bg-imagen-telepagos {
  --background: var(--colorPrimary);
}

.bg-index-telepagos {
  --background: var(--colorPrimary);
}

.gb-gris {
  margin-top: 20rem;
  position: absolute;
  width: 100%;
  height: 10rem;
  --background: #F6F6F8 ;
}

.card-index {
  height: 7.2rem;
  width: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0 !important;
}
.card-index .row-card-index {
  width: 90%;
}
.card-index .row-card-index .contenedor-wp {
  display: flex;
}
.card-index .row-card-index .contenedor-wp ion-label {
  margin-left: 1rem;
}

.bg-card-azul {
  --background: var(--colorPrimary);
}

.bg-card-uno {
  --background: var(--colorPrimary);
}

.row-favoritos-agenda {
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
}

.col-favoritos-agenda {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0;
  padding-bottom: 0.5rem !important;
  margin-right: -0.2rem;
  line-height: 1rem;
}

.row-recuperar-acceso {
  height: calc(var(--alto-app) * 0.2);
}

.row-recuperar-acceso-email {
  height: calc(var(--alto-app) * 0.25);
}

.row-encabezado-crear-password {
  height: calc(var(--alto-app) * 0.2);
  justify-content: center;
}

.row-titulo-registro-email {
  height: calc(var(--alto-app) * 0.20);
}

.row-inicio-normal {
  height: calc(var(--alto-app) * 0.80);
}

.position-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 2.5rem;
}

.item-verificacion-celular {
  --padding-start: 0 !important;
}

.input-verificacion-celular {
  font-size: 0.9rem !important;
}

.align-horizontal {
  display: flex;
  justify-content: center;
}

.card-menos-margin-top {
  margin-top: -2rem;
}

.card-movimientos-nm {
  margin-left: 0;
  margin-right: 0;
  margin-top: 1rem;
  box-shadow: none !important;
  z-index: 3 !important;
}

.version-texto {
  text-align: center;
}

ion-alert {
  --max-width: 80% !important;
}
ion-alert .alert-wrapper {
  width: 100% !important;
  border-radius: 0.5rem !important;
  padding: 0.5rem !important;
}
ion-alert .alert-title {
  color: var(--colorSecondary) !important;
}
ion-alert .alert-radio-group {
  border: none !important;
}
ion-alert .alert-button-group {
  justify-content: space-around !important;
  font-weight: bold !important;
}
ion-alert .alert-button-group .secondary {
  color: var(--colorAccent) !important;
}
ion-alert .alert-button-group .alert-button-role-cancel {
  color: var(--colorAccent) !important;
}
ion-alert .alert-button-group .alert-button {
  font-weight: bold !important;
}

.success {
  --background: var(--colorSuccess);
}

.danger {
  --background: var(--colorDanger);
}

.text-danger {
  color: #dc3545 !important;
}